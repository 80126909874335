<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>
    <div class="tabs">
      <v-tabs v-model="tab" background-color="primary" class="elevation-2" dark>
        <v-tab>{{ $t("COMPONENTS.RAW_PRODUCT") }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <vue-form-generator
                ref="form"
                :schema="rawProductSchema"
                :model="rawProduct"
                :options="formOptions"
              ></vue-form-generator>

              <vue-form-generator
                ref="moreForm"
                :schema="moreSchema"
                :model="rawProduct"
                :options="formOptions"
              ></vue-form-generator>

              <v-btn
                @click="save()"
                class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                >{{ $t("BUTTON.SAVE") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css"; // optional full css additions

import {
  GET_RAW_PRODUCT,
  SAVE_RAW_PRODUCT,
  UPDATE_RAW_PRODUCT
} from "@/store/rawProducts.module";

import { mapGetters, mapState } from "vuex";
// Vue.use(VueFormGenerator)

import { GET_DATA } from "@/store/formselect.module";

import { GET_CLIENTS } from "@/store/clients.module";

import _ from "lodash";

export default {
  name: "createUpdate",

  components: {
    "vue-form-generator": VueFormGenerator.component
  },
  computed: {
    ...mapGetters(["isLoadingRawProducts"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  data() {
    return {
      error_messages: [],
      vendors: [],
      model: "rawProducts",
      tab: 0,
      rawProduct: {
        name: null,
        qIB: null,
        primaryQIB: null,
        primaryQuantity: null
      },
      formTypes: [],
      manufacturers: [],
      rawProductSchema: {
        fields: [
          {
            id: "name",
            type: "input",
            inputType: "text",
            label: "Име",
            model: "name",
            required: true,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително !!!",
              textTooSmall: "Полето трябва да бъде минимум {0} знака !!!"
            })
          },
          {
            type: "input",
            inputType: "text",
            label: "Концентрация",
            model: "strength",
            required: true,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително !!!",
              textTooSmall: "Полето трябва да бъде минимум {0} знака !!!"
            })
          },
          {
            type: "input",
            inputType: "number",
            label: "Първични опаковки в кутия",
            model: "primaryQIB",
            required: true,
            class: "noscroll",
            min: 1,
            validator: VueFormGenerator.validators.number.locale({
              fieldIsRequired: "Полето е задължително !!!",
              numberTooSmall:
                "Стойността на полето трябва да бъде минимум {0}!!!"
            })
          }
        ]
      },
      moreSchema: {
        fields: []
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: true
      }
    };
  },
  mounted() {
    let vm = this;

    _.delay(function() {
      var current_page = "Добави";
      if (typeof vm.$route.params.id !== "undefined") {
        current_page = "Промени";
      }
      vm.$store.dispatch(SET_BREADCRUMB, [
        { title: "Суровини", route: "/raw-products" },
        { title: current_page }
      ]);
    }, 300);

    this.getTypes();

    if (typeof this.$route.params.id !== "undefined") {
      this.getData().then(() => {
        vm.getVendors();
      });
    } else {
      vm.getVendors();
    }
  },
  methods: {
    getTypes: function() {
      let vm = this;

      let payload = {
        formTypes: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.error_messages = [];
      this.$store
        .dispatch(GET_DATA, payload)
        .then(data => {
          vm.$nextTick(function() {
            vm.formTypes = data.data.formTypes;

            let formTyper = {
              type: "select",
              label: "Лекарствена форма",
              model: "formTypeId",
              required: true,
              selectOptions: { noneSelectedText: "избери" },
              values: vm.formTypes,
              validator: VueFormGenerator.validators.required.locale({
                fieldIsRequired: "Полето е задължително !!!"
              })
            };

            vm.rawProductSchema.fields.push(formTyper);
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getVendors: function() {
      let vm = this;

      let payload = {
        vendors: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.error_messages = [];
      this.$store
        .dispatch(GET_DATA, payload)
        .then(data => {
          vm.$nextTick(function() {
            vm.vendors = _.orderBy(data.data.vendors, "name");

            if (typeof this.$route.params.id !== "undefined") {
              vm.rawProduct.manufacturersData.forEach(manufacturer => {
                if (manufacturer.isArchived) {
                  vm.vendors.push({
                    id: manufacturer.id,
                    name: manufacturer.name + " (Архивиран)"
                  });
                }
              });
            }

            vm.vendors = _.uniqBy(vm.vendors, "id");

            let values = [];
            vm.vendors.forEach(el => {
              values.push(el.id);
            });

            let manufacturerId = {
              type: "vueMultiSelect",
              label: "Производители",
              placeholder: "Избери производители",
              model: "manufacturers",
              required: true,
              values: values,
              selectOptions: {
                multiSelect: true,
                multiple: true,
                closeOnSelect: false,
                customLabel: op => _.find(vm.vendors, ["id", op]).name
              },
              validator: value => {
                return !value || !value.length
                  ? ["Полето е задължително !!!"]
                  : [];
              }
            };

            vm.rawProductSchema.fields.push(manufacturerId);

            let maHolderId = {
              type: "input",
              inputType: "text",
              label: "Притежател на разрешение за употреба",
              model: "maHolder",
              required: true,
              validator: VueFormGenerator.validators.required.locale({
                fieldIsRequired: "Полето е задължително !!!"
              })
            };

            vm.rawProductSchema.fields.push(maHolderId);

            vm.getClients();
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    async save() {
      let vm = this;

      vm.error_messages = [];

      let errors = await this.$refs.form.validate();
      let moreErrors = await this.$refs.moreForm.validate();

      if (errors.length || moreErrors.length) {
        return;
      }

      var action =
        typeof this.$route.params.id !== "undefined"
          ? UPDATE_RAW_PRODUCT
          : SAVE_RAW_PRODUCT;

      vm.$store
        .dispatch(action, { payload: vm.rawProduct })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.$router.push({ name: "rawProductsList" });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getData: function() {
      let vm = this;
      this.error_messages = [];
      let id = this.$route.params.id;

      return this.$store
        .dispatch(GET_RAW_PRODUCT, id)
        .then(data => {
          vm.$nextTick(function() {
            vm.rawProduct = { ...data.data };

            vm.rawProduct.manufacturersData = [...data.data.manufacturers];

            vm.rawProduct.manufacturers = [];
            data.data.manufacturers.forEach(el => {
              vm.rawProduct.manufacturers.push(el.id);
            });
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
            vm.$router.push({ name: "rawProductsList" });
          }
        });
    },
    getClients: function() {
      let vm = this;

      // let id = this.$route.params.id;
      this.error_messages = [];
      this.$store
        .dispatch(GET_CLIENTS)
        .then(data => {
          vm.$nextTick(function() {
            vm.clients = data.data;

            if (typeof this.$route.params.id !== "undefined") {
              if (vm.rawProduct.clientIsArchived) {
                vm.clients.push({
                  id: vm.rawProduct.clientId,
                  name: vm.rawProduct.clientName + " (Архивиран)"
                });

                vm.clients = _.uniqBy(vm.clients, "id");
              }
            }

            let clientId = {
              type: "select",
              inputType: "text",
              label: "Клиент",
              model: "clientId",
              required: true,
              selectOptions: { noneSelectedText: "избери" },
              values: vm.clients,
              validator: VueFormGenerator.validators.required.locale({
                fieldIsRequired: "Полето е задължително !!!"
              })
            };

            let note = {
              type: "textArea",
              label: "Забележка",
              model: "note"
            };

            vm.moreSchema.fields.push(clientId);
            vm.moreSchema.fields.push(note);
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    }
  }
};
</script>
